<template>
  <div id="section-content" class="container container-fluid">
    <div class="bar-back">
      <router-link to="/member/main"><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link>
    </div>
    <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 140px) - 50px)">
      <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0">
        <div class="lotto-title w-100 d-flex justify-content-between">
          <div class="d-inline">
            <h4 class="mr-1 d-inline"><i class="fas fa-folder-plus"></i> เพิ่มบัญชีธนาคาร</h4>
            <small style="display: inline-block">(เติมเครดิตครั้งแรก และเพิ่มบัญชีธนาคารอื่นๆ)</small>
          </div>
        </div>
      </div>
      <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-5 pb-0">
        <form id="addbank" @submit.prevent="onSubmit" class="w-100">
          <div id="firsttime" style="display: block">
            <div class="form-row">
              <div class="col-12 col-sm-12 col-md-6">
                <label><i class="fas fa-university"></i> เลือกธนาคาร</label>
                <div class="border rounded mb-2">
                  <div class="dropdown bootstrap-select form-control">
                    <button
                      class="btn bs-placeholder btn-light"
                      style="width: 100%"
                      type="button"
                      data-toggle="dropdown"
                      aria-readonly="readonly"
                    >
                      <span v-html="selector"></span>
                      <span class="pic arrow-down"></span>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a
                        v-for="(item, index) in listMaster"
                        v-bind:key="index"
                        class="dropdown-item"
                        @click="onSelector(item)"
                      >
                        <img
                          style="padding: 2px; border-radius: 2px; width: 22px"
                          :src="imglink(item.bankCode)"
                          :style="`background:${item.bankColor}`"
                        />
                        <small class="badge badge-light text-dark text-uppercase">{{ item.bankCode }}</small>
                        <span>{{ item.bankName }}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 mb-1">
                <label><i class="fas fa-id-card"></i> ชื่อบัญชี</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  name="name"
                  placeholder="ชื่อ นามสกุล หน้าสมุดบัญชี"
                  v-model="$v.nameBank.$model"
                  v-on:keyup="updateMyValue($event)"
                  style="
                    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAfBJREFUWAntVk1OwkAUZkoDKza4Utm61iP0AqyIDXahN2BjwiHYGU+gizap4QDuegWN7lyCbMSlCQjU7yO0TOlAi6GwgJc0fT/fzPfmzet0crmD7HsFBAvQbrcrw+Gw5fu+AfOYvgylJ4TwCoVCs1ardYTruqfj8fgV5OUMSVVT93VdP9dAzpVvm5wJHZFbg2LQ2pEYOlZ/oiDvwNcsFoseY4PBwMCrhaeCJyKWZU37KOJcYdi27QdhcuuBIb073BvTNL8ln4NeeR6NRi/wxZKQcGurQs5oNhqLshzVTMBewW/LMU3TTNlO0ieTiStjYhUIyi6DAp0xbEdgTt+LE0aCKQw24U4llsCs4ZRJrYopB6RwqnpA1YQ5NGFZ1YQ41Z5S8IQQdP5laEBRJcD4Vj5DEsW2gE6s6g3d/YP/g+BDnT7GNi2qCjTwGd6riBzHaaCEd3Js01vwCPIbmWBRx1nwAN/1ov+/drgFWIlfKpVukyYihtgkXNp4mABK+1GtVr+SBhJDbBIubVw+Cd/TDgKO2DPiN3YUo6y/nDCNEIsqTKH1en2tcwA9FKEItyDi3aIh8Gl1sRrVnSDzNFDJT1bAy5xpOYGn5fP5JuL95ZjMIn1ya7j5dPGfv0A5eAnpZUY3n5jXcoec5J67D9q+VuAPM47D3XaSeL4AAAAASUVORK5CYII=');
                    background-repeat: no-repeat;
                    background-attachment: scroll;
                    background-size: 16px 18px;
                    background-position: 98% 50%;
                  "
                  required
                />
              </div>
              <div class="col-12 col-sm-12 col-md-6 mb-1">
                <label><i class="fas fa-clipboard-check"></i> เลขที่บัญชี</label>
                <input
                  type="tel"
                  class="form-control bankinput"
                  id="acc"
                  name="acc"
                  v-model.trim="$v.numberBank.$model"
                  placeholder="ระบุเฉพาะตัวเลข"
                  maxlength="13"
                  required
                />
                <small id="checkacc1"></small>
              </div>
              <div class="col-12 col-sm-12 col-md-6 mb-1">
                <label><i class="fas fa-check-double"></i> ยืนยันเลขที่บัญชี</label>
                <input
                  type="tel"
                  class="form-control bankinput"
                  id="reacc"
                  name="reacc"
                  v-model.trim="$v.confirmNumberBank.$model"
                  placeholder="ยืนยันเลขที่บัญชีอีกครั้ง"
                  maxlength="13"
                  required
                />
                <small id="checkacc2"></small>
              </div>
            </div>
          </div>

          <hr />
          <div class="row">
            <div class="col pr-1">
              <router-link to="/member/main">
                <button class="btn btn-secondary btn-block" type="reset">ยกเลิก</button>
              </router-link>
            </div>
            <div class="col pl-1">
              <input
                type="submit"
                value="เพิ่มบัญชี"
                class="btn btn-success btn-block"
                :disabled="$v.$invalid || submited || !checkEqual"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { bankList, STATUS_SUCCESS } from '@/constants'
import { mapActions, mapState } from 'vuex'
import baac from '@/assets/banks/baac.svg'
import bay from '@/assets/banks/bay.svg'
import bbl from '@/assets/banks/bbl.svg'
import bnp from '@/assets/banks/bnp.svg'
import boa from '@/assets/banks/boa.svg'
import cacib from '@/assets/banks/cacib.svg'
import cimb from '@/assets/banks/cimb.svg'
import citi from '@/assets/banks/citi.svg'
import db from '@/assets/banks/db.svg'
import ghb from '@/assets/banks/ghb.svg'
import gsb from '@/assets/banks/gsb.svg'
import hsbc from '@/assets/banks/hsbc.svg'
import ibank from '@/assets/banks/ibank.svg'
import icbc from '@/assets/banks/icbc.svg'
import jpm from '@/assets/banks/jpm.svg'
import kbank from '@/assets/banks/kbank.svg'
import kkp from '@/assets/banks/kkp.svg'
import ktb from '@/assets/banks/ktb.svg'
import lhb from '@/assets/banks/lhb.svg'
import mb from '@/assets/banks/mb.svg'
import mega from '@/assets/banks/mega.svg'
import mufg from '@/assets/banks/mufg.svg'
import rbs from '@/assets/banks/rbs.svg'
import sc from '@/assets/banks/sc.svg'
import scb from '@/assets/banks/scb.svg'
import smbc from '@/assets/banks/smbc.svg'
// import tcap from '@/assets/banks/tcap.svg'
import tisco from '@/assets/banks/tisco.svg'
import tmb from '@/assets/banks/tmb.svg'
import uob from '@/assets/banks/uob.svg'
import ttb from '@/assets/banks/ttb.svg'
import { required } from 'vuelidate/lib/validators'
import { get, isEqual, toNumber, checkNumber, isEmpty } from '@/utils'
import { listBankMasterService } from '@/services'
export default {
  data: () => {
    return {
      bankList: bankList,
      listMaster: [],
      selector: 'กรุณาเลือกธนาคาร',
      selectBank: null,
      nameBank: null,
      numberBank: null,
      confirmNumberBank: null,
      imageBankMapping: {
        baac: baac,
        bay: bay,
        bbl: bbl,
        bnp: bnp,
        boa: boa,
        cacib: cacib,
        cimb: cimb,
        citi: citi,
        db: db,
        ghb: ghb,
        gsb: gsb,
        hsbc: hsbc,
        ibank: ibank,
        icbc: icbc,
        jpm: jpm,
        kbank: kbank,
        kkp: kkp,
        ktb: ktb,
        lhb: lhb,
        mb: mb,
        mega: mega,
        mufg: mufg,
        rbs: rbs,
        sc: sc,
        scb: scb,
        smbc: smbc,
        // tcap: tcap,
        tisco: tisco,
        tmb: tmb,
        uob: uob,
        ttb: ttb
      },
      submited: false
    }
  },
  async created () {
    this.setLoading(true)
    console.log('isEnableCheckBankName page step 1')
    console.log(this.$store.getters.isEnableCheckBankName)
    await this.reqConfig({
      id: process.env.VUE_APP_AG_ID,
      afKey: this.afKey,
      agKey: this.agKey
    })
    console.log('isEnableCheckBankName page step 2')
    if (Number(this.$store.getters.isEnableCheckBankName) === Number(1)) {
      console.log('goto bank')
      this.$router.replace('/member/settings/bank/add')
      return
    }
    try {
      const resp = await listBankMasterService()
      if (resp.status_code === STATUS_SUCCESS) {
        this.listMaster = resp.data.listBank
      }
    } catch (error) {}
    this.setLoading(false)
  },
  methods: {
    ...mapActions(['getMyAccountBanks', 'reqConfig', 'addNewBookBank', 'setLoading', 'openErrorPopup', 'setLoading', 'openErrorPopup', 'openInfoPopup']),
    imglink (short) {
      return get(this.imageBankMapping, [short])
    },
    onSelector (data) {
      this.selector = `<img style="padding:2px;border-radius:2px;width:22px;background:${data.bankColor};" 
       src=${this.imglink(data.bankCode)} />
                <small class="badge badge-light text-dark text-uppercase">${data.bankCode}</small>
                    <span class="overflow-text">${data.bankName}</span>`
      this.selectBank = data.bankCode
    },
    updateMyValue (event, index, key) {
      let myValue = event.target.value.trim() // Formatting example
      const pattern_thai = /^[ก-๏\s]+$/u
      let isChange = false
      if (myValue.length > 0) {
        while (!myValue.match(pattern_thai) && myValue.length > 0) {
          isChange = true
          myValue = myValue.substring(0, myValue.length - 1)
        }
        if (isChange) {
          this.nameBank = myValue
        }
      }
    },
    async onSubmit () {
      try {
        this.setLoading(true)
        this.submited = true
        if (
          !isEmpty(this.selectBank) &&
          !isEmpty(this.nameBank) &&
          !isEmpty(this.confirmNumberBank) &&
          !isEmpty(this.numberBank) &&
          isEqual(toNumber(this.confirmNumberBank), toNumber(this.numberBank))
        ) {
          const resp = await this.addNewBookBank({
            bankCode: this.selectBank.toUpperCase(),
            accountName: this.nameBank,
            accountNumber: this.numberBank
          })
          this.setLoading(false)
          if (resp.status_code !== 'SUCCESS') {
            this.openErrorPopup(resp.status_msg)
            this.selector = 'กรุณาเลือกธนาคาร'
            this.selectBank = null
            this.nameBank = null
            this.confirmNumberBank = null
            this.numberBank = null
            this.submited = false
          } else {
            this.getMyAccountBanks()
            this.$router.push('/member/main')
            this.openInfoPopup()
          }
        }
      } catch (error) {
        this.setLoading(false)
        this.submited = false
      }
    }
  },
  watch: {
    numberBank: function (value) {
      if (!isEmpty(value)) {
        if (!checkNumber(value)) {
          this.numberBank = value.substring(0, value.length - 1)
        }
      }
    },
    confirmNumberBank: function (value) {
      if (!isEmpty(value)) {
        if (!checkNumber(value)) {
          this.confirmNumberBank = value.substring(0, value.length - 1)
        }
      }
    }
  },
  computed: {
    ...mapState({
      afKey: (state) => state.registerModule.afKey,
      agKey: (state) => state.registerModule.agKey
    }),
    checkEqual () {
      return isEqual(toNumber(this.confirmNumberBank), toNumber(this.numberBank))
    }
  },
  validations () {
    return {
      selectBank: {
        required
      },
      nameBank: {
        required
      },
      numberBank: {
        required
      },
      confirmNumberBank: {
        required
      }
    }
  }
}
</script>
<style scoped>
.overflow-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
  display: inline-grid;
}
.pic {
  margin: 10px; /* demo spacing */
  float: right;
  vertical-align: middle;
  width: 0;
  height: 0;
}

.arrow-down {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #737373;
}
.dropdown-menu{
  transform: translate3d(0px, 0px, 0px) !important;
  max-height: calc(-120px + 60vh);
        overflow-y: scroll;
        overflow-x: hidden;

}
</style>
